.book_wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;
}

.inner_wrapper {
    width: 1400px;
    height: 100vh;
    /* background-color: rgba(0, 255, 255, 0.411); */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.book_page {
    /* background-color: white;
    box-shadow: inset 0px 0px 27px -4px rgba(112, 112, 112, 1); */
    border-radius: 5px;
    overflow: hidden;
    /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
}

.book_page_stack_right {
    box-shadow:
    /* The top layer shadow */ 0 1px 1px rgba(0, 0, 0, 0.15),
        /* The second layer */ -5px 10px 0 -5px #eee,
        /* The second layer shadow */ 0 10px 1px -4px rgba(0, 0, 0, 0.15),
        /* The third layer */ -10px 20px 0 -10px #eee,
        /* The third layer shadow */ 0 20px 1px -9px rgba(0, 0, 0, 0.15);
}
.book_page_stack_left {
    box-shadow:
    /* The top layer shadow */ 0 1px 1px rgba(0, 0, 0, 0.15),
        /* The second layer */ 5px 10px 0 -5px #eee,
        /* The second layer shadow */ 0 10px 1px -4px rgba(0, 0, 0, 0.15),
        /* The third layer */ 10px 20px 0 -10px #eee,
        /* The third layer shadow */ 0 20px 1px -9px rgba(0, 0, 0, 0.15);
}

.page_overlay_old {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* background-color: rgba(189, 102, 3, 0.212); */
    box-shadow: inset 0px 0px 27px -4px rgba(112, 112, 112, 1);
    pointer-events: none;
}

.page_overlay_left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    /* box-shadow: inset -14px 0px 36px -16px rgba(145, 145, 145); */
    box-shadow: inset -27px 0px 10px -10px rgba(179, 173, 179, 0.4);
    pointer-events: none;
}

.page_overlay_right {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 10px 0px 36px -16px rgb(145, 145, 145);
    pointer-events: none;
}

.side_btn {
    height: 120px;
    width: 60px;
    position: fixed;
    top: 50%;
    /* font-size: 4rem; */
    transform: translateY(-50%);
    cursor: pointer;
    opacity: 0.3;
    transition: 0.5s;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.side_btn:hover {
    opacity: 1;
}

.prev_btn {
    left: 5px;
    /* border-radius: 0 100px 100px 0; */
}

.next_btn {
    right: 5px;
    /* border-radius: 100px 0 0 100px; */
}

.corner_btn {
    position: fixed;
    border-radius: 10px;
    width: 60px;
    height: 60px;
    background-color: white;
    opacity: 0.3;
    font-size: 1.6rem;
}

.corner_btn:hover {
    cursor: pointer;
    opacity: 1;
}

.back_btn {
    left: 5px;
    top: 5px;
}

.pages_btn {
    left: 5px;
    bottom: 5px;
}

.info_btn {
    right: 5px;
    bottom: 5px;
}

.theme_btn {
    right: 5px;
    top: 5px;
}

.center_title {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 2rem;
    background-color: white;
}

.first_page_view {
    transform: translateX(-25%);
    transition: 1s;
}

.last_page_view {
    transform: translateX(25%);
    transition: 0.7s;
}

.mid_page_view {
    transition: 0.7s;
}

.bottom_panel {
    position: absolute;
    bottom: 0;
    height: 45px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5px;
}

.panel_item {
    width: 40px;
    height: 40px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    opacity: 0.5;
    cursor: pointer;
}

.panel_item_center {
    width: 80px;
    height: 40px;
    background-color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    opacity: 0.5;
    font-weight: bold;
}

.panel_item:hover {
    opacity: 1;
}

.half_left {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
}

.half_right {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
}

@media only screen and (max-width: 600px) {
    .inner_wrapper {
        justify-content: center;
    }
    .bottom_panel {
        left: 0;
        right: 0;
        bottom: 0;
        display: grid;
        grid-template-columns: 1fr 1fr 2fr 1fr 1fr;
    }
    .panel_item {
        width: auto;
    }
}
