.wrapper {
	width: 100%;
	// height: 100%;
	height: auto;
	// border: 1px dotted gray;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	// gap: 10px;
	// overflow-x: hidden;
	// overflow-y: hidden;
	// overflow-y: auto;
	// box-sizing: border-box;
}

.resizer {
	position: absolute;
	left: 0;
	right: 0;
	top: 60%;
}
