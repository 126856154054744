.ql-container.ql-snow {
    height: auto;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    border: 1px dashed #dbdbdb !important;
}

.ql-editor {
    min-height: 60vh;
    max-height: 65vh;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: auto;
    /* font-size: 40px; */
}

#toolbar {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border: none;
    border-bottom: 1px solid #dbdbdb;
}

.ql-color .ql-picker-options [data-value="custom-color"] {
    background: none !important;
    width: 100% !important;
    height: 20px !important;
    text-align: center;
}
.ql-color .ql-picker-options [data-value="custom-color"]:before {
    content: "Custom Color";
}
.ql-color .ql-picker-options [data-value="custom-color"]:hover {
    border-color: transparent !important;
}

.ql-font-comic-sans,
.ql-font span[data-value="comic-sans"]::before {
    font-family: "Comic Sans MS", cursive, sans-serif;
}
.ql-font-courier-new,
.ql-font span[data-value="courier-new"]::before {
    font-family: "Courier New";
}
.ql-font-georgia,
.ql-font span[data-value="georgia"]::before {
    font-family: Georgia, serif;
}
.ql-font-helvetica,
.ql-font span[data-value="helvetica"]::before {
    font-family: Helvetica, sans-serif;
}
.ql-font-lucida,
.ql-font span[data-value="lucida"]::before {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

.quill-hr {
    border: 2px solid black;
}
