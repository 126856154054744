.cimg {
    transition: 0.5s ease;
    backface-visibility: hidden;
    // box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border: 1px solid rgb(221, 219, 219);
    transition: all 0.5s ease-in-out;
    background-color: yellow;
}

.cimg img {
    background-color: white;
    transition: 0.5s ease;
    // transform: scale(0.9);
}

.middle {
    transition: 0.5s ease;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.cimg:hover img {
    cursor: pointer;
    // filter: brightness(0.6) grayscale(100%) blur(3px);
    // filter: brightness(0.6);
    // filter: invert(50%) sepia(48%) saturate(4531%) hue-rotate(307deg)
    // 	brightness(93%) contrast(86%);
    filter: brightness(0.5);
    // transform: scale(1.05) rotate(-1deg);
    transform: scale(1.03);
    // backface-visibility: hidden;
    // perspective: 1000;
    // transform: translate3d(0, 0, 0);
    // transform: translateZ(0);
}

.cimg:hover .middle {
    opacity: 1;
    cursor: pointer;
    // background-color: aqua;
}

.selected {
    border: 5px solid teal;
}
