.grid3 {
	display: grid;
	grid-template-columns: 40px 40px 40px;
	row-gap: 5px;
	column-gap: 1px;
}

.grid4 {
	display: grid;
	grid-template-columns: 40px 40px 40px 40px;
	row-gap: 5px;
	column-gap: 1px;
}

.grid5 {
	display: grid;
	grid-template-columns: 40px 40px 40px 40px 40px;
	row-gap: 5px;
	column-gap: 1px;
}

.item {
	margin: 0;
}
