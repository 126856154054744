.react-grid-item.react-grid-placeholder {
    background: rgb(41, 69, 228) !important;
    opacity: 0.2;
    transition-duration: 100ms;
    z-index: 2;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.react-resizable-handle {
    position: absolute;
    width: 12px !important;
    height: 12px !important;
    /* right: -6px !important;
    bottom: -6px !important; */
    background-color: royalblue;
    border-radius: 100%;
    color: royalblue;
    opacity: 0.75;
    visibility: hidden;
    cursor: se-resize;
}

.react-resizable-handle-e {
    right: -6px !important;
}

.react-resizable-handle-s {
    bottom: -6px !important;
}

.react-resizable-handle-se {
    right: -6px !important;
    bottom: -6px !important;
}

.grid_wrapper {
    width: 100%;
    height: 100%;
    /* background-color: pink; */
}

.grid_item_wrapper {
    position: relative;
    background-color: transparent;
    z-index: 5;
    box-sizing: border-box;
    transition: 0.2s;
    outline: 1px solid rgba(65, 105, 225, 0.281);
}

.grid_item_wrapper:hover {
    outline: 1px solid royalblue;
}

.grid_item_label {
    position: absolute;
    top: 0;
    right: 0;
    background-color: royalblue;
    border-bottom-left-radius: 3px;
    padding: 0px 4px 2px 8px;
    visibility: hidden;
    z-index: 7;
    color: white;
    opacity: 0.75;
}

.dragLabel {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: royalblue;
    border-top-left-radius: 3px;
    padding: 1px 2px 2px 5px;
    visibility: hidden;
    z-index: 7;
}

.grid_item_wrapper:hover .grid_item_label {
    visibility: visible;
    cursor: move;
}

.grid_item_wrapper:hover .dragLabel {
    visibility: visible;
    cursor: move;
}

.grid_item_wrapper:hover .react-resizable-handle {
    visibility: visible;
}

.grid_element_wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

/* For Preview */

.grid_item_wrapper_preview {
    position: relative;
    background-color: transparent;
    z-index: 5;
    box-sizing: border-box;
}
