.q_hide_on_mobile {
    display: block;
}

.q_hide_on_tablet {
    display: block;
}

@media (max-width: 768px) {
    .q_hide_on_tablet {
        display: none;
    }
}

@media (max-width: 480px) {
    .q_hide_on_mobile {
        display: none;
    }
}

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

h1 {
    font-size: 36px;
}

h2 {
    font-size: 30px;
}

h3 {
    font-size: 24px;
}

h4 {
    font-size: 20px;
}

h5 {
    font-size: 18px;
}

h6 {
    font-size: 16px;
}

// p::first-letter {
//     font-size: 200%;
//     color: #8a2be2;
// }
