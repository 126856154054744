.wrapper {
    position: absolute;
    left: 102%;
    top: 10px;

    height: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    // box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    box-shadow: 0px 0px 8px 1px rgba(222, 222, 222, 1);
    z-index: 10;
    border-radius: 5px;
    overflow: hidden;
}

.item {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: white;
    color: rgb(192, 192, 192);
    // transition: 0.2s;
    font-size: 0.8rem;
    user-select: none;
    &:hover {
        cursor: pointer;
        color: white;
        background-color: royalblue;
    }
}

.itemSelected {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    background-color: royalblue;
    color: white;
    font-size: 0.8rem;
    user-select: none;
}

.itemText {
    font-weight: bold;
    font-size: 1rem;
}
