.wrapper {
    // outline: 1px dashed rgb(210, 217, 255);
    position: relative;
    background-color: transparent;
    z-index: 5;
    box-sizing: border-box;
    transition: 0.2s;
    &:hover {
        outline: 1px solid royalblue;
    }
}

.label {
    position: absolute;
    top: 0;
    right: 0;
    background-color: royalblue;
    // border-top: 1px solid rgb(145, 145, 145);
    // border-right: 1px solid rgb(145, 145, 145);
    border-bottom-left-radius: 3px;
    padding: 0px 4px 2px 8px;
    visibility: hidden;
    z-index: 7;
    color: white;
}

.dragLabel {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: royalblue;
    border-top-left-radius: 3px;
    padding: 1px 2px 2px 5px;
    visibility: hidden;
    z-index: 7;
}

.wrapper:hover .label {
    visibility: visible;
    cursor: move;
}

.wrapper:hover .dragLabel {
    visibility: visible;
    cursor: move;
}
