.canvas {
    border: 1px solid rgb(199, 199, 199);
    position: relative;
}

.targett {
    width: 70px;
    height: 70px;
    border: 1px solid #9ab0f09f;
    position: absolute;
}

.moveable-control {
    width: 14px !important;
    height: 14px !important;
    border: 2px solid white !important;
    margin-top: -7px !important;
    margin-left: -7px !important;
    background-color: royalblue !important;
}

.moveable-line {
    position: absolute;
    width: 2px;
    height: 2px;
    background: rgb(177, 177, 177) !important;
    transform-origin: 0px 0.5px;
}
