.wrapper {
    border: 1px solid transparent;
    position: relative;
    border: 1px solid transparent;
    &:hover {
        border: 1px dotted rgb(145, 145, 145);
    }
    display: flex;
    // justify-content: center;
    // align-items: center;
}

.label {
    height: 24px;
    position: absolute;
    top: -12px;
    padding-left: 13px;
    padding-right: 10px;
    z-index: 8;
    color: white;
    background-color: rgb(67, 111, 243);
    text-align: center;
    border-radius: 10px;
    left: calc(50% - 24px);
    visibility: hidden;
    opacity: 0.4;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding-bottom: 5px;
}

.label > .mmenu {
    opacity: 1;
}

.wrapper:hover .label {
    visibility: visible;
    cursor: pointer;
}

.label:hover {
    opacity: 1;
}

.psudo {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border: 2px solid rgb(55, 94, 211);
    visibility: hidden;
    z-index: 7;
}

.label:hover + .psudo {
    visibility: visible;
}
