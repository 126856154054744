.wrapper {
    // outline: 1px solid rgb(204, 203, 203);
    position: relative;
    &:hover {
        outline: 1px solid rgb(196, 196, 196);
    }
}

.label {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
    border-top-right-radius: 5px;
    padding: 1px 4px;
    visibility: hidden;
    z-index: 7;
    opacity: 0.4;
}

.wrapper:hover .label {
    visibility: visible;
    cursor: pointer;
}

.label:hover {
    opacity: 1;
}

.psudo {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    border: 2px solid royalblue;
    visibility: hidden;
    z-index: 6;
}

.label:hover + .psudo {
    visibility: visible;
}

.addBtn {
    width: 24px;
    height: 24px;
    z-index: 8;
    background-color: rgb(197, 209, 248);
    color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: bold;
    position: absolute;
    visibility: hidden;
    opacity: 0.5;
}

.addBtn:hover {
    opacity: 1;
    background-color: royalblue;
}

.wrapper:hover .addBtn {
    visibility: visible;
    cursor: pointer;
}

.bottomBtn {
    left: calc(50% - 12px);
    bottom: -12px;
}

.rightBtn {
    top: calc(50% - 12px);
    right: -12px;
}
